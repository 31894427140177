export const primaryColor = "#12cad6";
export const secondaryColor = "#12cad6";
export const accentTextColor = "#404040";
export const lightTextColor = "#b1bbcd";
export const dangerColor = "#F6412D";
export const orangeColor = "#FF9800";
export const greenColor = "#4BB543";
export const warningColor = "#FFEC19";
export const whiteColor = "#ffffff";
export const blackColor = "#000000";
export const black2Color = "#3c434d";
export const grayColor = "#757a87";
export const GOOGLE_MAPS_API_KEY = "AIzaSyA6NP5OW6Nqhc1bhq6H_TMNve_ye-DPZAU"; // "AIzaSyBYMpXjBKuzCJAeivAbJi4X1RenRQATZbU";
export const currencySymbol = "$"
export const LOCAL_STORAGE_STORE_DISPLAY_KEY = 'storeDisplay'
export const DISCOUNT_TYPE_PERCENTAGE = "percentage"
export const DISCOUNT_TYPE_QUANTITY = "quantity"
export const firebaseConfig = {
  apiKey: "AIzaSyA6NP5OW6Nqhc1bhq6H_TMNve_ye-DPZAU",
  authDomain: "now-fashion.firebaseapp.com",
  databaseURL: "https://now-fashion.firebaseio.com",
  projectId: "now-fashion",
  storageBucket: "now-fashion.appspot.com",
  messagingSenderId: "371589419746",
  appId: "1:371589419746:web:8c344458da410a5bf844bb",
  measurementId: "G-CQNTQ61J2N",
};

export const ORDERS_STATUS = {
  new: { text: "Pendiente", color: "#808080" },
  working: { text: "En preparación", color: greenColor },
  paid: { text: "Pagada", color: primaryColor },
  cancelled: { text: "Cancelada", color: "#F6412D" },
  ready: { text: "Listo para retiro", color: greenColor },
  sent: { text: "En preparación", color: greenColor },
  "En tránsito": { text: "En tránsito", color: greenColor },
  "En preparación": { text: "En preparación", color: greenColor },
  Entregado: { text: "Entregado", color: greenColor },
  dispatched: { text: "En tránsito", color: greenColor },
  payment_pending: { text: "Pago pendiente", color: "#ffcc00" },
  payment_rejected: { text: "Pago rechazado", color: "#F6412D" },
  awaiting_payment: { text: "Pago pendiente", color: "#ffcc00" },
};

const StoreDetailMenu = {
  img: true,
  first_title: "Outfits Exclusivos",
  second_title: "para ti",
  subtitle: "Búqueda avanzada",
  fields: [
    {
      title: "Sub categorías",
      type: "Select",
      id: "categorías",
      data: [],
    },
    {
      title: "Familias",
      type: "Select",
      data: [],
      id: "family",
    },
    {
      title: "Talla",
      type: "Select",
      data: [],
      id: "size",
    },
    {
      title: "Color",
      type: "Select",
      data: [],
      id: "color",
    },
    {
      title: "Precio",
      type: "Select",
      data: [],
      id: "price",
    },
    // {
    //   type: "radio",
    //   data: [{ id: "radio", values: "Si" }],
    // },
  ],
};

export const CATEGORIES = [
  {
    title: "MUJER",
    img: "../../assets/images/mujeres.jpg",
    subcategories: [
      {
        title: "Zapatos",
        families: [
          { title: "Botines" },
          { title: "Botas" },
          { title: "Zapatillas" },
        ],
      },
      {
        title: "Ropa",
        families: [
          { title: "Chaquetas" },
          { title: "Polerones" },
          { title: "Chaquetas y sweaters" },
        ],
      },
      {
        title: "Deportes",
        families: [
          { title: "Zapatillas outdoor" },
          { title: "Zapatillas Running" },
          { title: "Chaquetas" },
        ],
      },
    ],
  },
  {
    title: "HOMBRE",
    img: "../../assets/images/hombres.jpg",
    subcategories: [
      {
        title: "Zapatos",
        families: [
          { title: "Botines" },
          { title: "Botas" },
          { title: "Zapatillas" },
        ],
      },
      {
        title: "Ropa",
        families: [
          { title: "Chaquetas" },
          { title: "Polerones" },
          { title: "Chaquetas y sweaters" },
        ],
      },
      {
        title: "Deportes",
        families: [
          { title: "Zapatillas outdoor" },
          { title: "Zapatillas Running" },
          { title: "Chaquetas" },
        ],
      },
    ],
  },
  {
    title: "BEBÉS Y NIÑOS",
    img: "../../assets/images/ninios.jpg",
    subcategories: [
      {
        title: "Zapatos",
        families: [
          { title: "Botines" },
          { title: "Botas" },
          { title: "Zapatillas" },
        ],
      },
      {
        title: "Ropa",
        families: [
          { title: "Chaquetas" },
          { title: "Polerones" },
          { title: "Chaquetas y sweaters" },
        ],
      },
      {
        title: "Deportes",
        families: [
          { title: "Zapatillas outdoor" },
          { title: "Zapatillas Running" },
          { title: "Chaquetas" },
        ],
      },
    ],
  },
  {
    title: "DECOHOGAR",
    img: "../../assets/images/decogar.jpg",
    subcategories: [
      {
        title: "Zapatos",
        families: [
          { title: "Botines" },
          { title: "Botas" },
          { title: "Zapatillas" },
        ],
      },
      {
        title: "Ropa",
        families: [
          { title: "Chaquetas" },
          { title: "Polerones" },
          { title: "Chaquetas y sweaters" },
        ],
      },
      {
        title: "Deportes",
        families: [
          { title: "Zapatillas outdoor" },
          { title: "Zapatillas Running" },
          { title: "Chaquetas" },
        ],
      },
    ],
  },
  {
    title: "FOR SALE",
    img: "../../assets/images/forsale.jpg",
    subcategories: [
      {
        title: "Zapatos",
        families: [
          { title: "Botines" },
          { title: "Botas" },
          { title: "Zapatillas" },
        ],
      },
      {
        title: "Ropa",
        families: [
          { title: "Chaquetas" },
          { title: "Polerones" },
          { title: "Chaquetas y sweaters" },
        ],
      },
      {
        title: "Deportes",
        families: [
          { title: "Zapatillas outdoor" },
          { title: "Zapatillas Running" },
          { title: "Chaquetas" },
        ],
      },
    ],
  },
];

export const STORES = [
  {
    backgroundColor: "#05acec",
    img: "../../assets/images/Grupo-854.png",
    title: "Bershka",
  },
  {
    backgroundColor: "#ec8505",
    img: "../../assets/images/Grupo-855.png",
    title: "Converse",
  },
  {
    backgroundColor: "#F6412D",
    img: "../../assets/images/Grupo-853.png",
    title: "Lacoste",
  },
  {
    backgroundColor: "#921953",
    img: "../../assets/images/Grupo-857.png",
    title: "Timberland",
  },
  {
    backgroundColor: "#0014ff",
    img: "../../assets/images/Grupo-852.png",
    title: "Tommy Hilfiger",
  },
  {
    backgroundColor: "#ff00c8",
    img: "../../assets/images/Grupo-854.png",
    title: "Bershka",
  },
  {
    backgroundColor: "#F6412D",
    img: "../../assets/images/Grupo-857.png",
    title: "Timberland",
  },
];

const StoreListMenu = {
  img: true,
  first_title: "Descubre todas",
  second_title: "nuestras tiendas",
  subtitle: "Búqueda avanzada",
  fields: [
    {
      title: "Categoria ",
      type: "Select",
      id: "categoria",
      data: [],
    },
    {
      title: "Buscar por marca",
      type: "text",
      id: "search",
      data: [],
    },
  ],
};

export const gendersMap = [
  {
    key: 'm',
    label: 'Masculino'
  },
  {
    key: 'f',
    label: 'Femenino'
  },
  {
    key: 'u',
    label: 'Unisex'
  }
]

const CheckoutFieldsContact = () => {
  const fields = [
    {
      title: "Nombre",
      id: "name",
      type: "text",
      width: "90%",
    },
    {
      title: "Apellido",
      id: "lastName",
      type: "text",
      width: "90%",
    },
    {
      title: "Correo electrónico",
      id: "email",
      type: "text",
      width: "90%",
    },
    {
      title: "Número de teléfono",
      id: "phone",
      type: "text",
      width: "90%",
      maxLength: 9,
    },
  ];
  return fields;
};

const CheckoutFieldsShippingInformation = () => {
  const fields = [
    {
      columns: [
        {
          columns: "0.5fr 0.5fr",
          fields: [
            {
              title: "Cuidad",
              id: "city",
              type: "text",
              width: "90%",
            },
            {
              title: "Zona",
              id: "zona",
              type: "text",
              width: "90%",
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          columns: "1fr",
          fields: [
            {
              title: "Calle o avenida",
              id: "address",
              type: "text",
              width: "95%",
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          columns: "0.5fr 0.5fr",
          fields: [
            {
              title: "N. de casa o edificio",
              id: "house",
              type: "text",
              width: "90%",
            },
            {
              title: "Codigo postal",
              id: "code",
              type: "text",
              width: "90%",
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          columns: "1fr",
          fields: [
            {
              title: "Instrucciones",
              id: "instructions",
              type: "text",
              width: "95%",
            },
          ],
        },
      ],
    },
  ];
  return fields;
};

const getMessage = () => {
  const message = [
    {
      approved: {
        colorTitle: "#b1bbcd",
        first_Title: "Tu compra se realizo",
        last_Title: "con exito",
        subTitle: "Pago Completado",
        colorSubtitle: "#3c434d",
        twoBtn: true,
        img: "../../assets/images/Grupo-883.png",
        gridColumns: "0.5fr 0.5fr",
        gridColumnsXs: "1fr",
        checkoutSuccess: true,
      },
    },
    {
      error: {
        colorTitle: "#b1bbcd",
        first_Title: "Ocurrio un error con",
        last_Title: "tu pago",
        subTitle: "Pago rechazado",
        colorSubtitle: "#ea4335",
        twoBtn: true,
        img: "../../assets/images/Grupo-907.png",
        gridColumns: "1fr",
        checkoutSuccess: false,
      },
    },
    {
      cancel: {
        colorTitle: "#b1bbcd",
        first_Title: "No termino el",
        last_Title: "proceso de pago",
        subTitle: "Pago cancelado",
        colorSubtitle: "#ea4335",
        twoBtn: true,
        img: "../../assets/images/Grupo-907.png",
        gridColumns: "1fr",
        checkoutSuccess: false,
      },
    },
    {
      payment_pending: {
        colorTitle: "#b1bbcd",
        first_Title: "Verificando su pago",
        last_Title: "ya que se encuentra",
        subTitle: "en revision",
        colorSubtitle: "#ffcc00",
        twoBtn: true,
        img: "../../assets/images/Grupo-1044.png",
        gridColumns: "1fr",
        checkoutSuccess: false,
      },
    },
    {
      payment_rejected: {
        colorTitle: "#b1bbcd",
        first_Title: "Su pago fue",
        last_Title: "rechazado",
        subTitle: "en estado rechazado",
        colorSubtitle: "#F6412D",
        twoBtn: true,
        img: "../../assets/images/Grupo-907.png",
        gridColumns: "1fr",
        checkoutSuccess: false,
      },
    },
    {
      email: {
        colorTitle: "#b1bbcd",
        first_Title: "Tu correo se registro",
        last_Title: "con exito",
        subTitle: "Correo electrónico confirmado",
        colorSubtitle: "#3c434d",
        twoBtn: false,
        img: "../../assets/images/Grupo-897.png",
        gridColumns: "1fr",
        checkoutSuccess: false,
      },
    },
  ];
  return message;
};

const FieldsProfile = () => {
  const fields = [
    {
      id: "name",
      title: "Nombre",
      type: "text",
    },
    {
      id: "surname",
      title: "Apellido",
      type: "text",
    },
    {
      id: "email",
      title: "Correo electrónico",
      type: "text",
    },
    {
      id: "phone",
      title: "Número de teléfono",
      type: "text",
    },
    {
      id: "birthday",
      title: "Fecha de nacimiento",
      type: "date",
    },
    {
      id: "genero",
      title: "Genero",
      type: "select",
    },
  ];
  return fields;
};

const WarehouseOrder = {
  statuses: {
    requested: {value: "requested", label: "Pendiente"},
    processing: {value: "processing", label: "En preparación"},
    completed: {value: "completed", label: "Entregada", color: greenColor},
    completed_partially: {value: 'completed_partially', label: 'Entregada parcialmente', color: orangeColor},
    cancelled: {value: "cancelled", label: "Rechazada", color: dangerColor},
    pending: {value: "pending", label: "Pendiente"},
    current: {value: "current", label: "Vigentes"},
    closed: {value: "closed", label: "Cerradas"},
    all: {value: "", label: "Todas"},
    partiallyDelivered: {value: "partially_delivered", label: "Parcial"},
    completelyDelivered: {value: "completely_delivered", label: "Completa"},
}}

WarehouseOrder.statuses.closedCollection = [
  WarehouseOrder.statuses.completed_partially.value,
  WarehouseOrder.statuses.completed.value,
  WarehouseOrder.statuses.cancelled.value
]

WarehouseOrder.statuses.openedCollection = [
  WarehouseOrder.statuses.requested.value,
  WarehouseOrder.statuses.processing.value
]

export const SalesShopOrder = {
  statuses: {
    awaitingPayment: { value: "awaiting_payment", label: "Pago en espera", color: "#FFB302" },
    paymentPending: { value: "payment_pending", label: "Pago en revisión", color: "#f77b18" },
    paymentRejected: { value: "payment_rejected", label: "Pago rechazado", color: "#FF3838" },
    partiallyPaid: { value: "partially_paid", label: "Pago parcial", color: "#710201" },
    paid: { value: "paid", label: "Pago exitoso", color: greenColor },
  }
}

export const ColgramBrandNames = ["colloky", 'opaline'];
export const ColgramSchoolCategoryName = 'escolar';
export const ColgramShoesCategoryName = 'zapatos';

export {
  StoreListMenu,
  StoreDetailMenu,
  CheckoutFieldsContact,
  CheckoutFieldsShippingInformation,
  getMessage,
  FieldsProfile,
  WarehouseOrder
};

export const ROLE_COMMERCE = 'ROLE_COMMERCE';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_BRAND = 'ROLE_BRAND';
export const ROLE_WAREHOUSE_WORKER = 'ROLE_WAREHOUSE_WORKER'
export const ROLE_PROMOTER = 'ROLE_PROMOTER'
export const ROLE_ANONYMOUS = null

export const rolesPaths = [
  {
    role: ROLE_BRAND,
    homePath: "/store-home",
    forbiddenPathRedirection: "/store-home",
    treePaths: [
      '/privacy-policy',
      '/terms-and-conditions',
      '/change-password',
      '/store-home/:initialPage?',
      '/store-order-details',
    ]
  },
  {
    role: ROLE_COMMERCE,
    homePath: "/store-home",
    forbiddenPathRedirection: "/store-home",
    treePaths: [
      '/privacy-policy',
      '/terms-and-conditions',
      '/change-password',
      '/store/warehousers',
      '/store/promoters',
      '/store-home/:initialPage?',
      '/store-settings',
      '/store-order-details',
      '/storage-commerce/:initialPage?',
      '/store/displays',
      '/store/display/scan',
      '/store/display/missing-items',
      '/store/display/successful-management'
    ]
  },
  {
    role: ROLE_WAREHOUSE_WORKER,
    homePath: "/warehouse/orders",
    forbiddenPathRedirection: "/warehouse/orders",
    treePaths: [
      '/privacy-policy',
      '/terms-and-conditions',
      '/change-password',
      '/warehouse/orders',
      '/warehouse/orders/:id/detail',
      '/warehouse/orders/:id/edit'
    ]
  },
  {
    role: ROLE_PROMOTER,
    homePath: "/storage-commerce",
    forbiddenPathRedirection: "/storage-commerce",
    treePaths: [
      '/privacy-policy',
      '/terms-and-conditions',
      '/change-password',
      '/warehouse/orders',
      '/storage-commerce/:initialPage?',
      '/warehouse/orders',
      '/warehouse/orders/:id/detail',
      '/shop/sales',
      '/store/displays',
      '/store/display/scan',
      '/store/display/missing-items',
      '/store/display/successful-management'
    ]
  },
  {
    role: ROLE_USER,
    homePath: "/catalog",
    forbiddenPathRedirection: "/catalog",
    treePaths: [
      '/',
      '/home/:id?',
      '/privacy-policy',
      '/terms-and-conditions',
      '/catalog',
      '/store-detail/:id/:initialPage?',
      '/catalog/:initialPage/:idBrand?',
      '/category-view/:category/:subcategory?/:family?',
      '/change-password',
      "/profile",
      "/purchase-history/:id",
      "/detail-order/:id",
      "/contact-information/",
      '/confirmation-message/:id?'
    ]
  },
  {
    role: ROLE_ANONYMOUS,
    homePath: "/catalog",
    treePaths: [
      '/',
      '/home/:id?',
      "/login",
      "/account-confirmation/:token",
      "/register",
      "/recover-password",
      "/recovery-password/:token",
      '/privacy-policy',
      '/terms-and-conditions',
      '/catalog',
      '/store-detail/:id/:initialPage?',
      '/catalog/:initialPage/:idBrand?',
      '/category-view/:category/:subcategory?/:family?',
      '/confirmation-message/:id?'
    ],
    forbiddenPathRedirection: "/login"
  }
]

export const salesShopOrderPaymentMethodsDataTemplate = [
  {
    'key': 'checkoutProLink',
    'name': 'Web app',
    'value': null
  },
  {
    'key': 'qrTramma',
    'name': 'Mobile app',
    'value': null
  }
]