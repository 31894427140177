import { create } from "zustand";
import { LOCAL_STORAGE_STORE_DISPLAY_KEY } from "../constants";

const useStoreDisplay = create(( set, get ) => ({
    isBooted: false,
    requiredItems: [],
    scannedItems: [],
    boot: (storeDisplay) =>  set(() => {
        const {requiredItems, scannedItems} = storeDisplay
        return {
            isBooted: true,
            requiredItems,
            scannedItems,
        }
    }),
    setStoreDisplay: async (storeDisplay) => set(() => {
        const currentState = get();
        const {requiredItems, scannedItems} = storeDisplay
        return {
            requiredItems: requiredItems || currentState.requiredItems,
            scannedItems: scannedItems || currentState.scannedItems
        };
    }),
    addScannedItem: async (scannedItem) => set((state) => {
        const {id} = scannedItem
        if (state.scannedItems.includes(id)) return state
        return {
            scannedItems: [...state.scannedItems, id]
        }
    }),
    getMissingItems: () => {
        const {requiredItems, scannedItems} = get()
        const missingItems = requiredItems.filter((requiredItem) => !scannedItems.includes(requiredItem.id))
        const missingItemsGroupedByProduct8Code = missingItems.reduce((acc, missingItem) => {
            const code8 = missingItem?.productId?.slice(0, 8)
            const itemKey = Object.keys(acc).find(itemKey  => acc?.[itemKey]?.code8 === code8)
            if (!itemKey) {
                acc.push({
                    code8,
                    items: [missingItem]
                })
            }else{
                acc[itemKey].items.push(missingItem)
            }   
            return acc
        }, [])
        const missingItemsGroupedByProduct8CodeSortDesc = missingItemsGroupedByProduct8Code.sort((a, b) => {
            return b.items.length - a.items.length
        })
        console.log("🚀 ~ missingItemsGroupedByProduct8Code ~ missingItemsGroupedByProduct8Code:", missingItemsGroupedByProduct8CodeSortDesc)

        const missingItemsSortedByRepeats = missingItemsGroupedByProduct8CodeSortDesc.reduce((acc, item) => {
            return acc.concat(item.items)
        }, [])

        return missingItemsSortedByRepeats
    },
    getRequiredItemByBarcode: (barcode) => {
        const {requiredItems} = get()
        const requireItemsWithoutDuplicates = requiredItems.filter((requiredItem) => {
            const requiredItemLength = requiredItems.filter((item) => item.productId.slice(0, 8) === requiredItem.productId.slice(0, 8)).length
            return requiredItemLength === 1
        })
        return requireItemsWithoutDuplicates.find((requiredItem) => (
            requiredItem.fullExternalIds.some((externalId) => externalId.startsWith(barcode)) || 
            requiredItem.fullEans.includes(barcode)
        ))
    }
}))

useStoreDisplay.subscribe(async (state) => {
    try {
        console.log("🚀 ~ useStoreDisplay.subscribe ~ state:", state)
        localStorage.setItem(LOCAL_STORAGE_STORE_DISPLAY_KEY, JSON.stringify(state));
    } catch (e) {
        console.error('Error saving to localStorage:', e);
    }
});

export default useStoreDisplay;